// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FreeTime_busyDayContainer__yIO2a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.FreeTime_busyDay__TIuBe {
  text-align: center;
  color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/selectTime/freeTime/FreeTime.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iCAAiC;AACnC","sourcesContent":[".busyDayContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 30vh;\n}\n\n.busyDay {\n  text-align: center;\n  color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"busyDayContainer": `FreeTime_busyDayContainer__yIO2a`,
	"busyDay": `FreeTime_busyDay__TIuBe`
};
export default ___CSS_LOADER_EXPORT___;
