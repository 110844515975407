import React from "react";
import cl from "./ServiceDatePlace.module.css";
import { HeadlineBody, HeadLineSemibold } from "../../../textStyles/TextStyleComponents";
import { useCheckboxStore } from "../../../store/CheckboxStore";
import fetchStore from "../../../store/FetchStore";

const ServiceDatePlace = ({ selfEmployedAddress }) => {
    const checkboxStore = useCheckboxStore();

    // const checkedIds = checkboxStore.getCheckedCheckboxIds();

    // const selectedServices = Object.values(fetchStore.servicesData).flatMap(categoryItems =>
    //     categoryItems.filter(item => checkedIds.includes(item.id.toString()))
    // );

    let selectedServices = [];

    Object.keys(fetchStore.servicesData).forEach(category => {
        fetchStore.servicesData[category].forEach(service => {
            if (checkboxStore.checkboxes.has(service.id)) {
                selectedServices.push(service.name);
            }
        });
    });



    const serviceLabel = selectedServices.length === 1 ? "Услуга:" : "Услуги:";

    function getTimeInMinutes(timeString) {
        const [hours, minutes] = timeString.split(":").map(Number);
        return hours * 60 + minutes;
    }

    function formatDate(day, monthIndex, time) {
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        const monthName = months[monthIndex];
        return `${day} ${monthName}, ${time}`;
    }

    function formatEndTime(time, duration) {
        const timeInMinute = getTimeInMinutes(time);
        const endTimeInMinute = timeInMinute + duration;
        const endHours = Math.floor(endTimeInMinute / 60);
        const endMinutes = (endTimeInMinute % 60).toString().padStart(2, '0');
        return `- ${endHours}:${endMinutes}`;
    }

    const day = checkboxStore.selectedDate.day;
    const monthIndex = checkboxStore.selectedDate.month;
    const time = checkboxStore.selectedTime;
    const duration = checkboxStore.totalSelectedTime;

    const dateString = formatDate(day, monthIndex, time);
    const endTime = formatEndTime(time, duration);

    return (
        <div className={cl.container}>
            {selectedServices.length === 1 ? (
                <div className={cl.inscriptionContainer}>
                    <HeadLineSemibold>{serviceLabel}</HeadLineSemibold>
                    <HeadlineBody>{selectedServices[0]}</HeadlineBody>
                </div>
            ) : (
                <div className={cl.servicesList}>
                    <HeadLineSemibold>{serviceLabel}</HeadLineSemibold>
                    {selectedServices.map((service, index) => (
                        <div key={index} className={cl.serviceElem}>
                            <HeadlineBody>
                                {index + 1}. {service}
                            </HeadlineBody>
                        </div>
                    ))}
                </div>
            )}

            <div className={cl.inscriptionContainer}>
                <HeadLineSemibold>Дата:</HeadLineSemibold>
                <HeadlineBody>{dateString}</HeadlineBody>
                <HeadlineBody className={cl.hintText}>{endTime}</HeadlineBody>
            </div>

            <div className={cl.inscriptionContainer}>
                <HeadLineSemibold>Место:</HeadLineSemibold>
                <HeadlineBody className={selfEmployedAddress === 'Мастер не указал адрес' ? cl.hintText : ''}>{selfEmployedAddress}</HeadlineBody>
            </div>

            <div className={cl.inscriptionContainer}>
                <HeadLineSemibold>Цена:</HeadLineSemibold>
                <HeadlineBody>{`${checkboxStore.totalSelectedPrice} ₽`}</HeadlineBody>
            </div>
        </div>
    );
};

export default ServiceDatePlace;
