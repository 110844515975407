// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LastStep_ftnt__yAViJ {
    padding-top: 9px;
    padding-left: 16px;
    color: var(--tg-theme-hint-color);
}

.LastStep_aboba__35OEW {
    margin-top: 10px;
    color: var(--tg-theme-hint-color);
}

.LastStep_link__wXSAE {
    /* color: var(--tg-theme-hint-color); */
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/lastStep/LastStep.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iCAAiC;AACrC;;AAEA;IACI,gBAAgB;IAChB,iCAAiC;AACrC;;AAEA;IACI,uCAAuC;IACvC,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".ftnt {\n    padding-top: 9px;\n    padding-left: 16px;\n    color: var(--tg-theme-hint-color);\n}\n\n.aboba {\n    margin-top: 10px;\n    color: var(--tg-theme-hint-color);\n}\n\n.link {\n    /* color: var(--tg-theme-hint-color); */\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ftnt": `LastStep_ftnt__yAViJ`,
	"aboba": `LastStep_aboba__35OEW`,
	"link": `LastStep_link__wXSAE`
};
export default ___CSS_LOADER_EXPORT___;
