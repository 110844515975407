import { useState, useEffect, useRef } from "react";
import cl from "./DualInputDropdown.module.css"
import { InputMask } from '@react-input/mask';
import validationStore from "../../../../store/ValidationStore";
import { ReactComponent as SelectRemindIcon } from "../../../../vectorIcons/SelectRemindIcon.svg"
import { HeadlineBody } from "../../../../textStyles/TextStyleComponents";
import { useCheckboxStore } from "../../../../store/CheckboxStore";

const options = [
    { value: 0, label: "Не напоминать" },
    { value: 15, label: "Напомнить за 15 минут" },
    { value: 30, label: "Напомнить за 30 минут" },
    { value: 60, label: "Напомнить за 1 час" },
    { value: 120, label: "Напомнить за 2 часа" },
    { value: 360, label: "Напомнить за 6 часов" },
    { value: 1440, label: "Напомнить за 1 сутки" },
];

const DualInputDropdown = () => {
    const tg = window.Telegram.WebApp;

    const checkboxStore = useCheckboxStore();

    // checkboxStore.setClientName
    // checkboxStore.setClientPhoneNumber

    const [nameOfClient, setNameOfClient] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [selectRemindIsVisible, setSelectRemindIsVisible] = useState(false);

    // const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const dropdownRef = useRef(null);

    const dropdownBorderStyleClass = tg.colorScheme === 'dark' ? cl.darkBorderDropdown : cl.lightBorderDropdown;
    const dropdownItemStyleClass = tg.colorScheme === 'dark' ? cl.darkDropdownItem : cl.lightDropdownItem;

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handlePhoneNumberFocus = (e) => {
        if (e.target.value === '') {
            e.target.value = '+7 (';
            setPhoneNumber('+7 ('); // Установите начальное значение при фокусировке
        }
    };

    const [selectedOption, setSelectedOption] = useState(() => {
        const savedOption = localStorage.getItem('selectedOption');
        checkboxStore.setClientRemindMinutes(localStorage.getItem('selectedOption'))
        return savedOption ? JSON.parse(savedOption) : { value: 0, label: "Не напоминать" };
    });

    useEffect(() => {
        const isPhoneNumberComplete = phoneNumber.length === 18;

        if (nameOfClient.trim() && isPhoneNumberComplete) {
            tg.MainButton.color = "#34C759";
            tg.MainButton.textColor = "#FFFFFF";
            tg.MainButton.enable();
        } else {
            tg.MainButton.disable();
            if (tg.colorScheme === "light") {
                tg.MainButton.color = "#e8e8e9";
                tg.MainButton.textColor = "#b9b9ba";
            } else {
                tg.MainButton.color = "#2f2f2f";
                tg.MainButton.textColor = "#606060";
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameOfClient, phoneNumber]);

    useEffect(() => {
        const savedName = localStorage.getItem('nameOfClient');
        const savedPhoneNumber = localStorage.getItem('phoneNumber');

        if (savedName) {
            setNameOfClient(savedName);
            checkboxStore.setClientName(nameOfClient);
        }
        if (savedPhoneNumber) {
            setPhoneNumber(savedPhoneNumber);
            checkboxStore.setClientPhoneNumber(phoneNumber);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorage.setItem('nameOfClient', nameOfClient);
        checkboxStore.setClientName(nameOfClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameOfClient]);

    useEffect(() => {
        localStorage.setItem('phoneNumber', phoneNumber);
        checkboxStore.setClientPhoneNumber(phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneNumber]);

    useEffect(() => {
        localStorage.setItem('selectedOption', JSON.stringify(selectedOption));
        checkboxStore.setClientRemindMinutes(selectedOption.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    const toggleRemindDropdown = () => {
        if (!selectRemindIsVisible) {
            setSelectRemindIsVisible(true);
        } else {
            setSelectRemindIsVisible(false);
        }
    }

    const handleRemindSelect = (option) => {
        setSelectedOption(option);
        setSelectRemindIsVisible(false);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest(`.${cl.selectContainer}`)) {
            setSelectRemindIsVisible(false);
        }
    };

    useEffect(() => {
        if (selectRemindIsVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectRemindIsVisible]);

    return (
        <div className={cl.container}>
            <div className={cl.inputWrapper} style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                <input
                    type="text"
                    value={nameOfClient}
                    onChange={(e) => validationStore.handleReguralInputChange(e, setNameOfClient)}
                    placeholder="Имя"
                    className={cl.inpt}
                    style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                />
                <div className={cl.borderBottom}></div>
            </div>
            <div className={cl.inputWrapper}>
                <InputMask
                    mask="+7 (___) ___-__-__"
                    value={phoneNumber}
                    replacement={{ _: /\d/ }}
                    onChange={handlePhoneNumberChange}
                    onFocus={handlePhoneNumberFocus}
                    className={cl.inpt}
                    placeholder="Номер телефона"
                />
                <div className={cl.borderBottom}></div>
            </div>

            <div className={cl.selectContainer} onClick={toggleRemindDropdown}>
                <HeadlineBody>{selectedOption.label}</HeadlineBody>
                <SelectRemindIcon />
            </div>

            {selectRemindIsVisible &&
                <div ref={dropdownRef} className={`${cl.dropdownContainer} ${dropdownBorderStyleClass}`}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`${cl.dropdownItem} ${dropdownItemStyleClass}
                            ${index === 0 && cl.firstDropdownItem}
                            ${index === options.length - 1 && cl.lastDropdownItem}
                            ${option.value === selectedOption.value && cl.linkText}
                            `}
                            onClick={() => handleRemindSelect(option)}
                        >
                            <HeadlineBody>{option.label}</HeadlineBody>
                            {index !== options.length - 1 && <div className={cl.borderBottom}></div>}
                        </div>
                    ))}
                </div>
            }

        </div>
    )
}

export default DualInputDropdown
