// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesHeader_header__JYbr8 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.ServicesHeader_subHeadLine__ij8\\+k {
    padding-top: 3px;
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/header/ServicesHeader.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iCAAiC;AACrC","sourcesContent":[".header {\n    margin-top: 25px;\n    margin-bottom: 25px;\n}\n\n.subHeadLine {\n    padding-top: 3px;\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ServicesHeader_header__JYbr8`,
	"subHeadLine": `ServicesHeader_subHeadLine__ij8+k`
};
export default ___CSS_LOADER_EXPORT___;
