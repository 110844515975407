// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceDatePlace_container__H53e8 {
    margin-top: 38px;
}

.ServiceDatePlace_inscriptionContainer__ESraS {
    margin-bottom: 6px;
    display: flex;
    gap: 3px;
}

.ServiceDatePlace_hintText__xKXun {
    color: var(--tg-theme-hint-color);
}

.ServiceDatePlace_servicesList__qk9yG {
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
}

.ServiceDatePlace_serviceElem__9RF-n {
    padding-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/lastStep/serviceDatePlace/ServiceDatePlace.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".container {\n    margin-top: 38px;\n}\n\n.inscriptionContainer {\n    margin-bottom: 6px;\n    display: flex;\n    gap: 3px;\n}\n\n.hintText {\n    color: var(--tg-theme-hint-color);\n}\n\n.servicesList {\n    margin-bottom: 6px;\n    display: flex;\n    flex-direction: column;\n}\n\n.serviceElem {\n    padding-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServiceDatePlace_container__H53e8`,
	"inscriptionContainer": `ServiceDatePlace_inscriptionContainer__ESraS`,
	"hintText": `ServiceDatePlace_hintText__xKXun`,
	"servicesList": `ServiceDatePlace_servicesList__qk9yG`,
	"serviceElem": `ServiceDatePlace_serviceElem__9RF-n`
};
export default ___CSS_LOADER_EXPORT___;
