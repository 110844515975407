import { useEffect, useState } from "react"
import cl from "./SingleInput.module.css"
import validationStore from "../../../store/ValidationStore"
import { useCheckboxStore } from "../../../store/CheckboxStore"


const SingleInput = ({ placeholder }) => {
    const [inputValue, setInputValue] = useState('')

    const checkboxStore = useCheckboxStore();

    useEffect(() => {
        checkboxStore.setClientComment(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])
    
    return (
        <div className={cl.container}>            
            <div className={cl.inptContainer}>
            <input
                
                value={inputValue}
                // onChange={(e) => setInputValue(e.target.value)}
                onChange={(e) => validationStore.handleReguralInputChange(e, setInputValue)}
                placeholder={placeholder}
                className={cl.inpt}
            />
            </div>
        </div>
    )
}

export default SingleInput