import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Services from "./pages/servicesPage/ServicesPage";
import Info from "./pages/info/Info";
import SelectTime from "./pages/SelectTime";
import LastStep from "./pages/lastStep/LastStep";
import ErrorPage from "./pages/errorPage/ErrorPage";

function App() {

  useEffect(() => {
    document.title = "Client";
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Services />} />
        <Route path="/info/:id" element={<Info />} />
        <Route path="/selectTime" element={<SelectTime />} />
        <Route path="/lastStep" element={<LastStep />} />
        <Route path="/errorPage" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;