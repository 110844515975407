// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesBlocks_blockName__ycD7A {
    color: var(--tg-theme-hint-color);
    padding-left: 15px;
    padding-bottom: 7px;
}

.ServicesBlocks_list__XDCkZ {
    background-color: var(--tg-theme-bg-color);
    border-radius: 10px;
}

.ServicesBlocks_block__LLktV {
    margin-bottom: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/services/blocks/ServicesBlocks.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".blockName {\n    color: var(--tg-theme-hint-color);\n    padding-left: 15px;\n    padding-bottom: 7px;\n}\n\n.list {\n    background-color: var(--tg-theme-bg-color);\n    border-radius: 10px;\n}\n\n.block {\n    margin-bottom: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockName": `ServicesBlocks_blockName__ycD7A`,
	"list": `ServicesBlocks_list__XDCkZ`,
	"block": `ServicesBlocks_block__LLktV`
};
export default ___CSS_LOADER_EXPORT___;
