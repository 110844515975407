import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HeadlineBody } from "../../textStyles/TextStyleComponents";
import ServicesHeader from "../../components/header/ServicesHeader";
import ServicesBlocks from "../../components/services/blocks/ServicesBlocks";
import { useCheckboxStore } from "../../store/CheckboxStore";
import fetchStore from "../../store/FetchStore";
import cl from './ServicesPage.module.css';
import axios from "axios";

const Services = observer(() => {
    const tg = window.Telegram.WebApp;

    const checkboxStore = useCheckboxStore();

    const checkboxCount = checkboxStore.getSelectedCheckboxCount();

    const navigate = useNavigate()

    useEffect(() => {
        const initData = tg.initData;

        const parseInitData = (initData) => {
            return Object.fromEntries(new URLSearchParams(initData));
        };

        const parsedData = parseInitData(initData);

        localStorage.setItem('actorUserId', JSON.parse(parsedData.user).id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const botUsername = params.get('botUsername');
        const actor = params.get('actor');
        const clientUserId = params.get('clientUserId');

        // если мастер записывает клиента, тогда в качестве идентификатора для запросов на сервер, отправляем userId мастера
        if (botUsername === 'GrosServiceBot') {
            console.log('Условие сработало');
            localStorage.setItem('botUsername', localStorage.getItem('actorUserId')); // actorUserId по факту является userId мастера
            localStorage.setItem('clientUserId', clientUserId);

        } else {
            if (botUsername) {
                localStorage.setItem('botUsername', botUsername);
                localStorage.setItem('clientUserId', localStorage.getItem('actorUserId'));
            }
        }

        if (actor) {
            localStorage.setItem('actor', actor);
        }




        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getServicesData = async () => {
            const botUsername = localStorage.getItem('botUsername')
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/services`);
                fetchStore.servicesData = (response.data);
                fetchStore.isServiceDataLoaded = true;
                console.log("Загружены данны о сервисах");
            } catch (error) {
                console.error('Ошибка загрузки данных о сервисах', error);
                const errorCode = error.response?.status || 500;
                const errorMessage = error.response?.statusText || 'Internal Server Error';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        }
        getServicesData();

        const getWorkedDaysData = async () => {
            const botUsername = localStorage.getItem('botUsername')
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`);
                fetchStore.workedDaysData = (response.data.workingDays.days);
                // console.log(this.workedDaysData)
                console.log("Загружены данные о рабочих днях");
            } catch (error) {
                console.error('Ошибка загрузки данных о рабочих днях', error);
                const errorCode = error.response?.error || 500;
                const errorMessage = error.response?.description || 'Internal Server Error';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        }
        getWorkedDaysData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    useEffect(() => {
        const handleMainButtonOnClick = () => {
            navigate("/selectTime");
        }

        tg.MainButton.onClick(handleMainButtonOnClick);

        tg.BackButton.hide();

        if (!tg.MainButton.isActive) {
            tg.MainButton.enable()
            tg.MainButton.color = tg.themeParams.button_color
            tg.MainButton.textColor = tg.themeParams.button_text_color

        }
        if (checkboxCount === 1) {
            tg.MainButton.text = "Далее";
        } else {
            tg.MainButton.text = "Далее (" + checkboxCount + " выбрано)";
        }

        if (checkboxCount !== 0 && !tg.MainButton.isVisible) {
            tg.MainButton.show();
        }

        if (checkboxCount === 0 && tg.MainButton.isVisible) {
            tg.MainButton.hide();
        }

        return () => {
            tg.MainButton.offClick(handleMainButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (!fetchStore.isServiceDataLoaded) {
        return null;
    }

    return (
        <div>
            {Object.keys(fetchStore.servicesData).length !== 0
                ? <div>
                    <ServicesHeader>Выберите услугу</ServicesHeader>
                    <ServicesBlocks />
                </div>

                : <div className={cl.noServicesContainer}>
                    <HeadlineBody className={cl.noServicesText}>Мастер пока не добавил свои услуги </HeadlineBody>
                </div>
            }
        </div>
    )
})

export default Services;