import { makeObservable, observable, action } from 'mobx';
// import axios from 'axios';
// import servicesDataFromMyJson from "../Services.json"
// import workedDaysDataFromMyJson from "../Time.json"

class FetchStore {
    servicesData = {};
    // servicesData = servicesDataFromMyJson;

    workedDaysData = [];
    // workedDaysData = workedDaysDataFromMyJson;

    isServiceDataLoaded = false;

    constructor() {
        makeObservable(this, {
            servicesData: observable,
            workedDaysData: observable,
            isServiceDataLoaded: observable,
            // fetchServicesData: action,
            // fetchWorkedDaysData: action,
            setServicesData: action
        }, { deep: true, });
    }

    setServicesData = (newData) => {
        this.servicesData = newData;
    }

}

const fetchStore = new FetchStore();

export default fetchStore;
