// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonForScroll_container__cfgsM {
    /* height: 50px; */
    display: flex;
    -right: 10px;
}

.ButtonForScroll_btn__60Kxa {
    width: 30px;
    /* background-color: black; */
    border: none;
    background-color: var(--tg-theme-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/components/selectTime/calendar/buttonForScroll/ButtonForScroll.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,0CAA0C;AAC9C","sourcesContent":[".container {\n    /* height: 50px; */\n    display: flex;\n    -right: 10px;\n}\n\n.btn {\n    width: 30px;\n    /* background-color: black; */\n    border: none;\n    background-color: var(--tg-theme-bg-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ButtonForScroll_container__cfgsM`,
	"btn": `ButtonForScroll_btn__60Kxa`
};
export default ___CSS_LOADER_EXPORT___;
