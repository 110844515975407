import { observable, action, makeObservable } from 'mobx';
import { createContext, useContext } from 'react';

class CheckboxStore {
  checkboxes = new Set();
  totalSelectedTime = 0;
  totalSelectedPrice = 0;
  selectedDate = null;
  selectedTime = null;
  
  clientName = '';
  clientPhoneNumber = '';
  clientComment = '';
  clientRemindMinutes = 0;
  // lastSelectedDate = null;

  constructor() {
    makeObservable(this, {
      checkboxes: observable,
      totalSelectedTime: observable,
      totalSelectedPrice: observable,
      selectedDate: observable,
      selectedTime: observable,

      clientName: observable,
      clientPhoneNumber: observable,
      clientComment: observable,
      clientRemindMinutes: observable,
      // lastSelectedDate: observable,
      toggleCheckbox: action,
      isCheckboxChecked: action,
      getSelectedCheckboxCount: action,
      // getCheckedCheckboxIds: action,
      setSelectedDate: action,
      setSelectedTime: action,
      clearSelectedTime: action,
      setClientName: action,
      setClientPhoneNumber: action,
      setClientComment: action,
      setClientRemindMinutes: action
      // setLastSelectedDate: action
    }, { deep: true, });
  }

  toggleCheckbox(id) {
    if (this.checkboxes.has(id)) {
      this.checkboxes.delete(id); // Убираем ID, если он был выбран
    } else {
      this.checkboxes.add(id); // Добавляем ID, если он не был выбран
    }
  }

  isCheckboxChecked(id) {
    return this.checkboxes.has(id); // Проверяем, выбран ли ID
  }

  getSelectedCheckboxCount() {
    return this.checkboxes.size; // Возвращаем количество выбранных чекбоксов
  }

  // getCheckedCheckboxIds() {
  //   return Array.from(this.checkboxes); // Возвращаем массив выбранных ID
  // }

  setSelectedDate(date) {
    this.selectedDate = date;
    this.selectedTime = null;
  }

  setSelectedTime(time) {
    this.selectedTime = time;
  }

  clearSelectedTime() {
    this.selectedTime = null;
  }

  setClientName(name) {
    this.clientName = name;
  }

  setClientPhoneNumber(phoneNumber) {
    this.clientPhoneNumber = phoneNumber;
  }

  setClientComment(comment) {
    this.clientComment = comment;
  }

  setClientRemindMinutes(minutes) {
    this.clientRemindMinutes = minutes;
  }

  // setLastSelectedDate(date) {
  //   this.lastSelectedDate = date;
  // }
}

const StoreContext = createContext(new CheckboxStore());

export const useCheckboxStore = () => useContext(StoreContext);

export default StoreContext;
