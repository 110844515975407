// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Info_time__pSS6H {
    padding-bottom: 25px;
}

.Info_description__Q5\\+sm {
    padding-bottom: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/info/Info.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".time {\n    padding-bottom: 25px;\n}\n\n.description {\n    padding-bottom: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": `Info_time__pSS6H`,
	"description": `Info_description__Q5+sm`
};
export default ___CSS_LOADER_EXPORT___;
