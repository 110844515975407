// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleInput_container__A1ju3 {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.SingleInput_inptContainer__ZFifD {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--tg-theme-bg-color);
    border-radius: 10px;
}

.SingleInput_inpt__PN9yK {
    background-color: var(--tg-theme-bg-color);
    height: 44px;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    padding-left: 16px;
    outline: none;
    border-radius: 10px;
}

.SingleInput_inpt__PN9yK::placeholder {
    color: var(--tg-theme-hint-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
}`, "",{"version":3,"sources":["webpack://./src/components/lastStep/singleInput/SingleInput.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,0CAA0C;IAC1C,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".container {\n    margin-top: 16px;\n    display: flex;\n    flex-direction: column;\n}\n\n.inptContainer {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    background-color: var(--tg-theme-bg-color);\n    border-radius: 10px;\n}\n\n.inpt {\n    background-color: var(--tg-theme-bg-color);\n    height: 44px;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    padding-left: 16px;\n    outline: none;\n    border-radius: 10px;\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SingleInput_container__A1ju3`,
	"inptContainer": `SingleInput_inptContainer__ZFifD`,
	"inpt": `SingleInput_inpt__PN9yK`
};
export default ___CSS_LOADER_EXPORT___;
