// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesPage_noServicesContainer__IcNVP {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.ServicesPage_noServicesText__LtsMz {
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/servicesPage/ServicesPage.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".noServicesContainer {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    \n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.noServicesText {\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noServicesContainer": `ServicesPage_noServicesContainer__IcNVP`,
	"noServicesText": `ServicesPage_noServicesText__LtsMz`
};
export default ___CSS_LOADER_EXPORT___;
