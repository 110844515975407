import { useNavigate } from "react-router-dom";
import ServicesHeader from "../../components/header/ServicesHeader";
import { useEffect, useState } from "react";
import DualInputDropdown from "../../components/lastStep/dualInputDropdown/dualInputDropdown/DualInputDropdown";
import SingleInput from "../../components/lastStep/singleInput/SingleInput";
import { Footnote } from "../../textStyles/TextStyleComponents";
import cl from "./LastStep.module.css"
import ServiceDatePlace from "../../components/lastStep/serviceDatePlace/ServiceDatePlace";
import { useCheckboxStore } from "../../store/CheckboxStore";
import axios from "axios";
import { observer } from "mobx-react-lite";
import fetchStore from "../../store/FetchStore";
import ModalUniversal from '../../components/universalComponents/modalUniversal/ModalUniversal'

const LastStep = observer(() => {
  let tg = window.Telegram.WebApp;
  const navigate = useNavigate();

  const checkboxStore = useCheckboxStore()

  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (Object.keys(fetchStore.servicesData).length === 0) {
      navigate('*')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(fetchStore.servicesData).length === 0) {
    return null;
  }

  const botUsername = localStorage.getItem('botUsername')

  const [selfEmployedAddress, setSelfEmployedAddress] = useState('Мастер не указал адрес');

  useEffect(() => {
    const getSelfEmployedRequest = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}`);
        if (response.data.address) setSelfEmployedAddress(response.data.address);

        console.log("Загружены данные для адреса", response);
      } catch (error) {
        if (error.response) {
          // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
          const errorData = error.response.data;
          console.error("Ошибка:", errorData.error);
          console.error("Описание:", errorData.description);
        } else if (error.request) {
          // Запрос был сделан, но ответа не было получено
          console.error("Запрос был сделан, но ответа не было получено", error.request);
        } else {
          // Произошла другая ошибка при настройке запроса
          console.error("Ошибка:", error.message);
        }
      }
    }

    getSelfEmployedRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const localDateTimeString = `${checkboxStore.selectedDate.year}-${(checkboxStore.selectedDate.month + 1).toString().padStart(2, '0')}-${checkboxStore.selectedDate.day.toString().padStart(2, '0')}T${checkboxStore.selectedTime}:00`;

  const localDate = new Date(localDateTimeString);

  const dateInUTC = localDate.toISOString();

  const date = `${checkboxStore.selectedDate.year}-${(checkboxStore.selectedDate.month + 1).toString().padStart(2, '0')}-${checkboxStore.selectedDate.day.toString().padStart(2, '0')} ${checkboxStore.selectedTime}`;

  let dateInUTCForRemind;
  if (checkboxStore.clientRemindMinutes !== 0) {
    const newUtcDate = new Date(dateInUTC);
    newUtcDate.setMinutes(newUtcDate.getMinutes() - checkboxStore.clientRemindMinutes);
    dateInUTCForRemind = newUtcDate.toISOString();
  } else {
    dateInUTCForRemind = null;
  }



  useEffect(() => {
    const handleBackButtonOnClick = () => {
      navigate("/selectTime");
    }

    const handleMainButtonOnClick = async () => {
      tg.MainButton.disable();

      const requestData = {
        clientUserId: localStorage.getItem('clientUserId'),
        date: date,
        dateInUTC: dateInUTC,
        comment: checkboxStore.clientComment,
        name: checkboxStore.clientName,
        phoneNumber: checkboxStore.clientPhoneNumber,
        notifyAt: dateInUTCForRemind,
        services: checkboxStore.checkboxes,
        actor: localStorage.getItem('actor')
      };

      try {
        const response = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/recordings`, requestData);
        console.log('Ответ от сервера:', response.data);
        tg.close();
      } catch (error) {
        setShowErrorModal(true);
        tg.MainButton.enable();
        if (error.response) {
          // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
          const errorData = error.response.data;
          console.error("Ошибка:", errorData.error);
          console.error("Описание:", errorData.description);
        } else if (error.request) {
          // Запрос был сделан, но ответа не было получено
          console.error("Запрос был сделан, но ответа не было получено", error.request);
        } else {
          // Произошла другая ошибка при настройке запроса
          console.error("Ошибка:", error.message);
        }
      }
    }

    tg.BackButton.onClick(handleBackButtonOnClick);

    tg.MainButton.disable();

    if (tg.colorScheme === "light") {
      tg.MainButton.color = "#e8e8e9";
      tg.MainButton.textColor = "#b9b9ba";
    } else {
      tg.MainButton.color = "#2f2f2f";
      tg.MainButton.textColor = "#606060";
    }

    tg.MainButton.setText("Записаться");

    tg.MainButton.onClick(handleMainButtonOnClick)

    return () => {
      tg.BackButton.offClick(handleBackButtonOnClick);
      tg.MainButton.offClick(handleMainButtonOnClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(checkboxStore.checkboxes)
  return (
    <div>
      <ServicesHeader>Последний шаг</ServicesHeader>
      <DualInputDropdown />
      <SingleInput placeholder={"Комментарий к записи"} />
      <Footnote className={cl.ftnt}>Любые пожелания, детали и т. д.</Footnote>
      <ServiceDatePlace selfEmployedAddress={selfEmployedAddress} />

      <Footnote className={cl.aboba}>Нажимая кнопку "Записаться", вы соглашаетесь с условиями <span className={cl.link} onClick={() => {tg.openLink('https://gros.pro/offer.html')}}>оферты</span> и принимаете <span className={cl.link} onClick={() => {tg.openLink('https://gros.pro/privacy-policy.html')}}>политику конфиденциальности</span></Footnote>

      {/* <button className={cl.link} onClick={() => {tg.openLink('https://gros.pro/offer.html')}}>оферты</button>
      <button className={cl.link} onClick={() => {tg.openLink('https://gros.pro/privacy-policy.html')}}>политику конфиденциальности</button> */}

      {showErrorModal &&
        <ModalUniversal
          text={'Не удалось записаться.\nПопробуйте снова'}
          setIsVisible={setShowErrorModal}
          cancelText={'Окей'}
        />
      }

    </div>
  )
})

export default LastStep