import React from "react";
// import axios from "axios";
import { Footnote } from "../../../textStyles/TextStyleComponents";
import ServicesList from "../list/ServicesList";
import cl from "./ServicesBlocks.module.css";
import clForLastChild from "../list/ServicesList.module.css";
// import { useState, useEffect } from "react";
// import CryptoJS from "crypto-js";
import fetchStore from "../../../store/FetchStore";
import { observer } from "mobx-react-lite";



const ServicesBlocks = observer(() => {
    
    // const currentUrl = new URL(window.location.href);

    // const searchParams = currentUrl.searchParams;

    // const botUsername = searchParams.get('bot_username');

    const servicesEntries = Object.entries(fetchStore.servicesData);
    


    // const sendCiphertext = async () => {
    //     const token = CryptoJS.AES.encrypt('API-TOKEN', 'ThisIsASecretKey').toString();
    //     console.log("aboba")
    //     try {
    //         const response = await axios.post('http://192.168.31.223:8080/api-token', { token });
    //         console.log(response.data);
    //     } catch (error) {
    //         console.error('Error sending ciphertext:', error);
    //     }

    // };
    
    
    return (
        <div>
            {servicesEntries.map(([category, items]) => (
                <div key={category} className={cl.block}>
                    <Footnote className={cl.blockName}>{category}</Footnote>
                    <div className={cl.list}>
                        {items.map(service => (
                            <div key={service.id} className={clForLastChild.containerForLastChild}>
                                <ServicesList
                                    services={{
                                        id: service.id,
                                        name: service.name,
                                        price: service.price,
                                        duration: service.duration, // Обратите внимание на изменение 'time' на 'duration'
                                        info: service.description // Изменение 'info' на 'description'
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
})

export default ServicesBlocks;