// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar_container__m5JyI {
    height: 147px;
    background-color: var(--tg-theme-bg-color);
    /* background-color: gray; */
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 25px;
}

.Calendar_monthYearContainer__x1HXN {
    padding-top: 20px;
    display: flex;
    
    margin-bottom: 13px;
}

.Calendar_monthText__09JJi {
    display: inline-block;
    margin-left: 20px;
}

.Calendar_yearText__jNxDE {
    display: inline-block;
    margin-left: 10px;
}



`, "",{"version":3,"sources":["webpack://./src/components/selectTime/calendar/Calendar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;;IAEb,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".container {\n    height: 147px;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: gray; */\n    margin-left: -15px;\n    margin-right: -15px;\n    margin-bottom: 25px;\n}\n\n.monthYearContainer {\n    padding-top: 20px;\n    display: flex;\n    \n    margin-bottom: 13px;\n}\n\n.monthText {\n    display: inline-block;\n    margin-left: 20px;\n}\n\n.yearText {\n    display: inline-block;\n    margin-left: 10px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Calendar_container__m5JyI`,
	"monthYearContainer": `Calendar_monthYearContainer__x1HXN`,
	"monthText": `Calendar_monthText__09JJi`,
	"yearText": `Calendar_yearText__jNxDE`
};
export default ___CSS_LOADER_EXPORT___;
